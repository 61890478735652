
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import styles from './ImageCarousel.module.css'
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};
const SingleImageCarousel = ({photos}) =>{
    return (
        <Carousel
  swipeable={true}
  draggable={false}
  showDots={false}
  responsive={responsive}
  ssr={true} // means to render carousel on server-side.
  infinite={true}
  autoPlay={true}
  autoPlaySpeed={2000}
  keyBoardControl={true}
  customTransition="all 300ms"
  transitionDuration={300}
  containerClass="carousel-container"
  dotListClass="custom-dot-list-style"
  itemClass="carousel-item-padding-40-px"
>
{
  photos?.map((image) =>(
    <div key={image.id} className={styles.singlCarousel}>
    <img src={image.path} alt="carousel_image" className="img-fluid" />
  </div>
  ))
}
</Carousel>
    )
}

export default SingleImageCarousel